module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#FFF","display":"standalone","icon":"src/images/favicon.png","logo":"src/images/logo.png","name":"Propellers.team. Консультируем, делаем дизайн, собираем, развиваем","short_name":"Propellers SPA","start_url":"https://propellers.team/","theme_color":"#076CFF","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f19dbbfb55f7092cc7b9e167716a438b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"62483137","webvisor":false,"trackHash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-analytics/gatsby-browser.js'),
      options: {"plugins":[],"appId":"955357184504374","version":"v3.3","xfbml":false,"cookie":false,"includeInDevelopment":false,"debug":false,"language":"ru_RU"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
